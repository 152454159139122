import { Loading } from 'components';
import EndorserTitlebar from 'components/TitleBar/EndorserTitlebar';
import { useEffect, useState } from 'react';
import styles from './index.module.less';

const Audits = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const iframeEle = document.getElementById('iframe');
    iframeEle?.addEventListener('load', () => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById('iframe')]);

  return (
    <>
      <EndorserTitlebar title="Audits" fontSize className={styles.endorsers} />
      {loading ? <Loading /> : null}
      <iframe
        id="iframe"
        src="https://globalseafood.metabaseapp.com/public/dashboard/08414ff4-1034-42d6-acfc-7d1fb37c7a56#theme=transparent&bordered=false&titled=false"
        title="Audits"
        style={{ border: 'none', height: '2000px' }}
        width="100%"
      />
    </>
  );
};

export default Audits;
